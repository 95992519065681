
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Publication } from './types'
import { parseReview } from '@/utils/utils'
import ArticleSourceModal from '@/components/ArticleSourceModal.vue'
import InternalLinks from '@/components/InternalLinks.vue'
import CollapsibleReview from '@/components/search-results/publications-results/CollapsibleReview.vue'

@Component({
  components: {
    ArticleSourceModal,
    InternalLinks,
    CollapsibleReview,
  },
})
export default class GenericFormat extends Vue {
  @Prop() item!: Publication
  @Prop({ default: false }) internalAccess!: boolean
  @Prop({ default: false }) unsubbed!: boolean

  showArticleModal = false

  //
  // COMPONENT PROPERTIES
  //

  get formattedMRNumber() {
    // Add leading zeros if needed
    const mrNumLength = 7
    const leadingZeros = mrNumLength - this.item.mrnumber.toString().length
    return '0'.repeat(leadingZeros) + this.item.mrnumber
  }

  get articleRoute() {
    return {
      name: 'ArticlePage',
      query: {
        // mr: this.item.mrnumber.toString(),
        mr: this.formattedMRNumber,
      },
    }
  }

  get isPrelim() {
    return this.item.itemType.toLowerCase() === 'prelim'
  }

  get hasArticle() {
    return (this.item.articleUrl && this.item.articleUrl !== '') || (this.item.openURL && this.item.openURL.targetLink.length > 0)
  }

  get title() {
    return parseReview(this.item.titles.title)
  }

  get translatedTitle() {
    return parseReview(this.item.titles.translatedTitle)
  }

  volumeFormatter(issue): string {
    if (!issue.volume) {
      return ''
    }
    let result = `${issue.volume}`
    if (issue.volume2) {
      if (issue.volSlash.toLowerCase() === 'p') {
        result = `${result}(${issue.volume2})`
      } else {
        result = `${result}/${issue.volume2}`
      }
    }
    if (issue.volume3) {
      if (issue.volSlash.toLowerCase() === 'p') {
        result = `${result}(${issue.volume3})`
      } else {
        result = `${result}/${issue.volume3}`
      }
    }
    return result
  }

  formatSerialLink(issue) {
    if (issue.journal.id !== null) {
      return { name: 'SerialProfile', query: { journalId: issue.journal.id } }
    }
    // It's an old entry that doesn't have a journalId/serialId. Have to search for it instead.
    return {
      name: 'JournalSearch',
      query: {
        query: `"${issue.journal.shortTitle}"`,
      },
    }
  }

  get volumeFormatted() {
    // if (!this.item.issue.issue.volume) {
    //   return ''
    // }
    // let result = `${this.item.issue.issue.volume}`
    // if (this.item.issue.issue.volume2) {
    //   if (this.item.issue.issue.volSlash.toLowerCase() === 'p') {
    //     result = `${result}(${this.item.issue.issue.volume2})`
    //   } else {
    //     result = `${result}/${this.item.issue.issue.volume2}`
    //   }
    // }
    // if (this.item.issue.issue.volume3) {
    //   if (this.item.issue.issue.volSlash.toLowerCase() === 'p') {
    //     result = `${result}(${this.item.issue.issue.volume3})`
    //   } else {
    //     result = `${result}/${this.item.issue.issue.volume3}`
    //   }
    // }
    // return result
    return this.volumeFormatter(this.item.issue.issue)
  }

  get translatedVolumeFormatted() {
    return this.volumeFormatter(this.item.issue.translatedIssue)
  }

  formatDisplayPaging(paging) {
    let text = this.dashReplacement(paging.text)
    const endChar = text.charAt(text.length - 1)
    if (endChar !== ',' && endChar !== '.') {
      text += '.'
    }
    return text
  }

  get displayPaging() {
    // Test with MR0620028
    // return this.clearPunctuation(this.paging.text)
    // let text = this.dashReplacement(this.item.paging.text)
    // const endChar = text.charAt(text.length - 1)
    // if (endChar !== ',' && endChar !== '.') {
    //   text += '.'
    // }
    // return text
    return this.formatDisplayPaging(this.item.paging.paging)
  }

  get translatedDisplayPaging() {
    return this.formatDisplayPaging(this.item.paging.translatedPaging)
  }

  get serialLink() {
    // if (this.item.issue.issue.journal.id !== null) {
    //   return { name: 'SerialProfile', query: { journalId: this.item.issue.issue.journal.id } }
    // }
    // // It's an old entry that doesn't have a journalId/serialId. Have to search for it instead.
    // return {
    //   name: 'JournalSearch',
    //   query: {
    //     query: `"${this.item.issue.issue.journal.shortTitle}"`,
    //   },
    // }
    return this.formatSerialLink(this.item.issue.issue)
  }

  get translatedSerialLink() {
    return this.formatSerialLink(this.item.issue.translatedIssue)
  }

  get mscRoute() {
    const route = {
      name: 'PublicationsSearch',
      query: {
        query: 'pc:"' + this.item.primaryClass.code + '"',
      },
    }
    return route
  }

  get reviewers() {
    if (this.item.prePubl &&
      this.item.prePubl.reviewer &&
      this.item.prePubl.reviewer.reviewers) {
      return this.item.prePubl.reviewer.reviewers
    } else {
      if (this.item.reviewer &&
      this.item.reviewer.reviewers
      ) {
        return this.item.reviewer.reviewers
      } else {
        return []
      }
    }
  }

  //
  // METHODS
  //

  dashReplacement(text: string) {
    text = text.replaceAll('---', '&mdash;')
    text = text.replaceAll('--', '&ndash;')
    return text
  }

  clearPunctuation(text: string) {
    // Clear trailing commas and periods, like in MR0507436
    text = this.dashReplacement(text)
    return text.replace(/\.$|,$/, '')
  }

  formatIssue(issue: string|number, multiIss: string|undefined = undefined) {
    // Some issue numbers include 'no. X,' which messes with the formatting
    let formatted = `${issue}`.replaceAll('no.', '').trim()
    formatted = this.clearPunctuation(formatted)
    if (multiIss) {
      formatted += '-'
      formatted += multiIss.replaceAll('no.', '').trim()
    }
    return formatted
  }

  toggleShowingFull() {
    const reviewEl = this.$refs.reviewDiv as HTMLElement
    if (reviewEl.classList.contains('fade-bottom')) {
      reviewEl.classList.remove('fade-bottom')
    } else {
      reviewEl.classList.add('fade-bottom')
    }
  }

  formatSearchLink(limit:string, issue) {
    let result = ''
    if (issue && issue.journal) {
      if (issue.journal.id !== null) {
        result += `ji:${issue.journal.id} `
      } else {
        // It's an old entry that doesn't have a journalId/serialId. Have to search for it instead.
        result += `j:"${issue.journal.shortTitle}" `
      }
    }
    if (limit === 'journal') {
      return result.trim() // .trim() added to help visited links show the correct color
    }
    if (limit === 'year') {
      // result += `y:${issue.pubYear} `
      result += `y:${issue.pubYearInt} `
      return result.trim()
    }
    if (issue && issue.volume) {
      result += `v:${issue.volume} `
    }
    if (limit === 'volume' || limit === 'vol') {
      return result.trim()
    }
    if (issue && issue.number) {
      result += `iss:${issue.number}`
    }

    if (limit === 'issue' || limit === 'iss') {
      return result.trim()
    }
    return result.trim()
  }

  genSearchLink(limit:string) {
    // let result = ''
    // if (this.item.issue && this.item.issue.issue.journal) {
    //   if (this.item.issue.issue.journal.id !== null) {
    //     result += `ji:${this.item.issue.issue.journal.id} `
    //   } else {
    //     // It's an old entry that doesn't have a journalId/serialId. Have to search for it instead.
    //     result += `j:"${this.item.issue.issue.journal.shortTitle}" `
    //   }
    // }
    // if (limit === 'journal') {
    //   return result.trim() // .trim() added to help visited links show the correct color
    // }
    // if (limit === 'year') {
    //   result += `y:${this.item.issue.issue.pubYear} `
    //   return result.trim()
    // }
    // if (this.item.issue && this.item.issue.issue.volume) {
    //   result += `v:${this.item.issue.issue.volume} `
    // }
    // if (limit === 'volume' || limit === 'vol') {
    //   return result.trim()
    // }
    // if (this.item.issue && this.item.issue.issue.number) {
    //   result += `iss:${this.item.issue.issue.number}`
    // }

    // if (limit === 'issue' || limit === 'iss') {
    //   return result.trim()
    // }
    // return result.trim()
    return this.formatSearchLink(limit, this.item.issue.issue)
  }

  genTranslatedSearchLink(limit:string) {
    return this.formatSearchLink(limit, this.item.issue.translatedIssue)
  }

  updateScrollInfo() {
    alert('HERE')
  }
}
