
import { PublicationsAPI } from '@/api'
import { Card } from '@/components/card'
import {
  CheckboxStage,
  FacetGroup, FacetItem, Facets,
  FacetsFields,
  FacetsValue,
  PublicationFacets,
  convertSectionsToFacetsString,
  fetchFacets,
} from '@/components/facets'
import { trackPublicationsSearchCounter5 } from '@/counter5-tracker'
import { Results, SearchParams, emptyResults } from '@/global-types'
import {
  UPDATE_PREV_NEXT_PUBS_STATE, UPDATE_EL_SCROLL_TO, UPDATE_LAST_SEARCH_NOT_MATCHING,
  UPDATE_LAST_SEARCH_HAS_ERRORS, LastSearchTypeOfItem, UPDATE_LAST_SEARCH_TYPE_OF_ITEM,
  RESET_LAST_SEARCH_STATE,
  UPDATE_LAST_SEARCH_EXPANDED_SEARCH,
  UPDATE_LAST_SEARCH_ERROR_MESSAGE,
} from '@/store'
import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator'
import ResultsComponent from './ResultsComponent.vue'
import { Publication } from './types'
import { defaultSearchParams, updateRouter } from './utils'
import { AxiosError } from 'axios'
import { ErrorMessage } from '@/api/types'

@Component({
  components: {
    ResultsComponent,
    Card,
    Facets,
  },
})
export default class PublicationsResults extends Vue {
  @Ref() searchResultsContainer!: HTMLDivElement
  @Ref() searchResultsBottom!: HTMLDivElement

  @VModel() searchParams!: SearchParams

  @Prop({ default: false, type: Boolean }) nestedComponentFormat!: boolean
  @Prop({ default: false }) internalAccess!: boolean
  @Prop({ default: false }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet
  @Prop({ default: 'publicationsPageSize' }) pageSizeKey!: string

  //
  // REACTIVE PROPERTIES
  //
  publicationsResults: Results<Publication> = emptyResults
  facetGroups: FacetGroup[] = []
  loadingPublications = false
  loadingFacets = false
  filtersModalOpened = false
  filtersOpened = false
  concatResults = false
  pageSize = 20
  nestedFormatNotMatchingResults = false
  // pageSizeKey = 'publicationsPageSize'

  /* -------------------------------------------------------------------------- */
  /*                                  WATCHERS                                  */
  /* -------------------------------------------------------------------------- */
  @Watch('searchParams', { deep: true, immediate: true })
  onSearchParamsChanged(newParams, oldParams) {
    if (!this.searchParams.sortBy) {
      this.searchParams.sortBy = 'newest'
    }

    this.changeSortConditionWhenFieldIsIncluded('mref:', 'relevance')(() => {
      this.pageSize = this.searchParams.pageSize

      const counter5TrackingNeeded = this.checkIfCounter5TrackingNeeded(newParams, oldParams)

      this.fetchData(counter5TrackingNeeded)
    })
  }

  @Watch('$store.getters.lastSearchShouldResetSearchParams', { deep: true })
  onStoreChanged(newValue) {
    if (newValue) {
      this.updateURLParams({ ...this.searchParams, facets: '' })
    }
  }

  @Watch('notMatchingResults', { immediate: true })
  onNotMatchingResultsChanged() {
    if (this.notMatchingResults && this.searchParams.query.indexOf('pc:') !== -1) {
      this.$store.commit(UPDATE_LAST_SEARCH_EXPANDED_SEARCH, true)
      this.updateURLParams({
        ...this.searchParams,
        query: this.searchParams.query.replaceAll('pc:', 'pcsc:'),
      }, true)
    }
  }

  //
  // COMPUTER PROPERTIES
  //

  get clearBtnVariant() {
    return this.nestedComponentFormat ? 'outline-primary' : ''
  }

  get facetsFromURL() {
    return this.searchParams.facets ? this.searchParams.facets : ''
  }

  get notMatchingResults() {
    if (this.nestedComponentFormat) {
      return this.nestedFormatNotMatchingResults
    }

    return this.$store.getters.lastSearchNotMatchingResults
  }

  set notMatchingResults(value: boolean) {
    if (this.nestedComponentFormat) {
      this.nestedFormatNotMatchingResults = value
    } else {
      this.$store.commit(UPDATE_LAST_SEARCH_NOT_MATCHING, value)
    }
  }

  get showError() {
    return this.$store.getters.lastSearchHasErrors
  }

  set showError(value: boolean) {
    this.$store.commit(UPDATE_LAST_SEARCH_HAS_ERRORS, value)
  }

  get errorMessage() {
    return this.$store.getters.lastSearchErrorMessage
  }

  set errorMessage(value: string) {
    this.$store.commit(UPDATE_LAST_SEARCH_ERROR_MESSAGE, value)
  }

  get lastSearchIsExpandedSearch() {
    return this.$store.getters.lastSearchIsExpandedSearch
  }

  //
  // HOOKS
  //

  mounted() {
    this.$store.dispatch(RESET_LAST_SEARCH_STATE)
    this.$store.commit(UPDATE_LAST_SEARCH_TYPE_OF_ITEM, LastSearchTypeOfItem.PUBLICATION)

    // this.pageSize = this.searchParams.pageSize
    if (this.nestedComponentFormat && localStorage.pubNestedSearchComponentFiltersOpened) {
      this.filtersOpened = JSON.parse(localStorage.pubNestedSearchComponentFiltersOpened)
    } else if (!this.nestedComponentFormat && localStorage.pubSearchComponentFiltersOpened) {
      this.filtersOpened = JSON.parse(localStorage.pubSearchComponentFiltersOpened)
    }
  }

  //
  // METHODS
  //

  changeSortConditionWhenFieldIsIncluded(field: string, sortBy: string) {
    return (action: () => void) => {
      const lastPublicationsQuery = localStorage.getItem('last_publications_query')
      localStorage.setItem('last_publications_query', this.searchParams.query)
      const query = this.searchParams.query.trim()

      if (
        query.includes(field) &&
        (lastPublicationsQuery === null || (lastPublicationsQuery !== null && lastPublicationsQuery.trim() !== query))
      ) {
        this.updateSortBy(sortBy, true)
      } else if (
        !query.includes(field) &&
        lastPublicationsQuery?.includes(field) && defaultSearchParams.sortBy
      ) {
        this.updateSortBy(defaultSearchParams.sortBy, true)
      } else {
        action()
      }
    }
  }

  checkIfCounter5TrackingNeeded(newParams: SearchParams, oldParams: SearchParams) {
    return !this.nestedComponentFormat && window.routerInfo &&
      (
        window.routerInfo.from.path === '/' ||
        window.routerInfo.from.path === window.routerInfo.to.path ||
        window.routerInfo.to.name === window.lastClickedRouterLinkInfo?.name
      ) &&
      (
        oldParams === undefined ||
        newParams.query !== oldParams.query ||
        newParams.facets !== oldParams.facets
      )
  }

  toggleFilters() {
    this.filtersOpened = !this.filtersOpened

    if (this.nestedComponentFormat) {
      localStorage.pubNestedSearchComponentFiltersOpened = this.filtersOpened
    } else {
      localStorage.pubSearchComponentFiltersOpened = this.filtersOpened
    }
  }

  toggleFiltersModal() {
    this.filtersModalOpened = !this.filtersModalOpened
  }

  ifQueryNonEmpty(run: () => void) {
    if (this.searchParams.query.trim().length > 0) {
      run()
    }
  }

  fetchData(trackCounter5 = false) {
    this.ifQueryNonEmpty(async() => {
      this.notMatchingResults = false

      this.fetchFacetsData()
      await this.fetchPublicationsData()
      const el = document.getElementById(this.$store.getters.elScrollTo)
      if (el) {
        el.scrollIntoView({ block: 'center' })
        this.$store.commit(UPDATE_EL_SCROLL_TO, undefined)
        const target = (el.children[0] as HTMLElement) // Selects the first child in the div, which is the MR Number link, and puts the focus there
        target.focus()
      }

      if (trackCounter5) trackPublicationsSearchCounter5()
    })
  }

  private async fetchPublicationsData() {
    this.loadingPublications = true
    this.showError = false
    try {
      let publicationsResults

      if (this.unsubbed) {
        // Can't use look behind in Safari. If it has `(?<` it will break the entire UI.
        const regex1 = /(^|\W)auid:([0-9]+)/g
        const regex2 = /(^|\W)mauid:([0-9]+)/g
        const regex3 = /(^|\W)ri:([0-9]+)/g
        const auid = regex1.exec(this.searchParams.query.trim())
        const mauid = regex2.exec(this.searchParams.query.trim())
        const ri = regex3.exec(this.searchParams.query.trim())

        publicationsResults = await PublicationsAPI.searchPublic({
          auid: auid ? parseInt(auid[2]) : undefined,
          mauid: mauid ? parseInt(mauid[2]) : undefined,
          ri: ri ? parseInt(ri[2]) : undefined,
          sort: this.searchParams.sortBy,
          page: this.searchParams.pageNumber,
          size: this.searchParams.pageSize,
        })
      } else {
        publicationsResults = await PublicationsAPI.search(
            `${this.searchParams.query} ${this.facetsFromURL}`.trim(),
            this.searchParams.pageNumber,
            this.searchParams.pageSize,
            this.searchParams.sortBy,
            this.searchParams.orderBy,
            !this.nestedComponentFormat,
        )
      }

      // reset page number if no results and search again
      if (
        publicationsResults.total > 0 &&
        publicationsResults.results.length === 0 &&
        this.searchParams.pageNumber > 1
      ) {
        this.updateCurrentPage(1)
      }

      // concat results if "MORE" button is used instead of pagination nav
      if (this.concatResults) {
        publicationsResults = {
          results: [...this.publicationsResults.results, ...publicationsResults.results],
          total: this.publicationsResults.total, // + publicationsResults.total,
        }
      }

      if (publicationsResults.total === 0) {
        this.notMatchingResults = true
      }

      if (
        !this.nestedComponentFormat &&
        publicationsResults.results &&
        publicationsResults.results.length === 1 &&
        !this.lastSearchIsExpandedSearch
      ) {
        this.$router.replace({
          name: 'ArticlePage',
          query: {
            mr: publicationsResults.results[0].mrnumber.toString(),
          },
        })
      } else {
        this.publicationsResults = publicationsResults

        this.$store.dispatch(UPDATE_PREV_NEXT_PUBS_STATE, {
          searchParams: this.searchParams,
          results: this.publicationsResults,
        })
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        try {
          this.errorMessage = ((e as AxiosError).response?.data as ErrorMessage).message
        } catch (e2) {
          this.errorMessage = (e as AxiosError).response?.data as string
        }
      }
      this.showError = true
    }

    this.loadingPublications = false
  }

  scrollToMR(mrnumber: number) {
    const result = document.getElementById('MR' + mrnumber)
    if (result != null) {
      const searchHeader = document.getElementById('search-bar-header')
      const searchHeaderHeight = searchHeader ? searchHeader?.getBoundingClientRect().height : 0
      window.scrollTo(0, result.getBoundingClientRect().top + window.scrollY - searchHeaderHeight)
    }
  }

  updateGroups(groups: FacetGroup[]) {
    this.facetGroups = groups
  }

  private async fetchFacetsData() {
    this.loadingFacets = true

    this.facetGroups = []
    this.facetGroups = await fetchFacets(
      this.searchParams,
      PublicationsAPI.getFacets,
      this.convertToFacetGroups,
      this.nestedComponentFormat,
    )

    // console.log('this.facetGroups', JSON.stringify(this.facetGroups, null, 2))
    this.loadingFacets = false
  }

  private convertToFacetGroups(facets: FacetsFields): FacetGroup[] {
    const results = facets as PublicationFacets

    const statusesGroup: FacetGroup = {
      id: 'st',
      title: 'Status',
      items: results.statuses
        ? results.statuses.map(this.itemFacetToFacetItem)
        : [],
      operations: {
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    const itemTypeGroup: FacetGroup = {
      id: 'fit',
      title: 'Item Type',
      items: results.itemTypes
        ? results.itemTypes.map(this.itemFacetToFacetItem)
        : [],
      operations: {
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    const entryTypeGroup: FacetGroup = {
      id: 'fet',
      title: 'Entry Type',
      items: results.entryTypes
        ? results.entryTypes.map(this.itemFacetToFacetItem)
        : [],
      operations: {
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    const reviewStatusGroup: FacetGroup = {
      id: 'frs',
      title: 'Review Status',
      items: results.reviewStatuses
        ? results.reviewStatuses.map(this.itemFacetToFacetItem)
        : [],
      operations: {
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    const publicationTypeGroup: FacetGroup = {
      id: 'fpt',
      title: 'Publication Type',
      items: results.publicationTypes
        ? results.publicationTypes.map(this.itemFacetToFacetItem)
        : [],
      operations: {
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    const authorsGroup: FacetGroup = {
      id: 'auid',
      title: 'Authors',
      items: results.authors
        ? results.authors.map(this.itemFacetToFacetItem)
        : [],
      searchable: true,
      showLinkedANDSearchSwitch: true,
      linkedANDSearchLabels: {
        terms: 'authors',
        document: 'publication',
      },
      operations: {
        filter: async(value: string) => {
          const results = await PublicationsAPI.getAuthorsFacet(
            this.searchParams.query.trim(),
            value
          )

          return results ? results.map(this.itemFacetToFacetItem) : null
        },
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    const primaryClassificationsGroup: FacetGroup = {
      id: 'fpc',
      title: 'Primary Classifications',
      items: results.primaryClassifications
        ? results.primaryClassifications.map(this.primClassFacetToFacetItem)
        : [],
      searchable: true,
      operations: {
        filter: async(value: string) => {
        // const facets = this.facetsFromURL.replace(/\sAND\s/g, ' ')
          const results = await PublicationsAPI.getPrimaryClassificationsFacet(
            this.searchParams.query.trim(),
            value
          )

          return results ? results.map(this.primClassFacetToFacetItem) : null
        },
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    const journalsGroup = {
      id: 'ji',
      title: 'Journals',
      items: results.journals
        ? results.journals
          .map(this.itemFacetToFacetItem)
        : [],
      searchable: true,
      operations: {
        filter: async(value: string) => {
          const results = await PublicationsAPI.getJournalsFacet(
            this.searchParams.query.trim(),
            value
          )

          return results ? results.map(this.itemFacetToFacetItem) : null
        },
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    const publicationYearsGroup = {
      id: 'y',
      title: 'Publication Years',
      items: results.publicationYears
        ? results.publicationYears.map(this.itemFacetToFacetItem)
        : [],
      operations: {
        filter: async(value: string) => {
          const results = await PublicationsAPI.getPublicationYearFacet(
            this.searchParams.query.trim(),
            value
          )

          return results ? results.map(this.itemFacetToFacetItem) : null
        },
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return parseInt(b.value) - parseInt(a.value)
          })
        },
      },
    }

    const institutionsGroup = {
      id: 'finst',
      title: 'Institutions',
      items: results.institutions
        ? results.institutions.map(this.itemFacetToFacetItem)
        : [],
      searchable: true,
      allowPunctuation: true,
      showLinkedANDSearchSwitch: true,
      linkedANDSearchLabels: {
        terms: 'institutions',
        document: 'publication',
      },
      operations: {
        filter: async(value: string) => {
          const results = await PublicationsAPI.getInstitutionsFacet(
            this.searchParams.query.trim(),
            [value]
          )

          return results ? results.map(this.itemFacetToFacetItem) : null
        },
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    return [
      this.internalAccess ? statusesGroup : null,
      this.internalAccess ? itemTypeGroup : null,
      this.internalAccess ? entryTypeGroup : null,
      reviewStatusGroup,
      publicationTypeGroup,
      authorsGroup,
      institutionsGroup,
      primaryClassificationsGroup,
      journalsGroup,
      publicationYearsGroup,
    ].filter(g => g !== null) as FacetGroup[]
  }

  private itemFacetToFacetItem(itemType: FacetsValue): FacetItem {
    return {
      value: itemType.id,
      label: itemType.value,
      count: itemType.total,
      stage: CheckboxStage.Unselected,
    }
  }

  private primClassFacetToFacetItem(pc: FacetsValue): FacetItem {
    return {
      value: pc.id,
      label: `<div class="d-flex"><div style="font-family: monospace;">${pc.id.toUpperCase()}</div><div>&nbsp;-&nbsp;</div><div>${pc.value}</div></div>`,
      count: pc.total,
      stage: CheckboxStage.Unselected,
    }
  }

  updateURLParams(searchParams: SearchParams, replace?: boolean) {
    if (!this.nestedComponentFormat) {
      updateRouter(this.$router, replace)(searchParams)
    } else {
      this.searchParams = searchParams
    }
  }

  updateCurrentPage(pageNumber: number) {
    this.concatResults = false
    this.updateURLParams({ ...this.searchParams, pageNumber })
  }

  updateInternalPageSize(pageSize: number) {
    this.pageSize = pageSize
    this.searchParams.pageNumber = 1
    this.searchParams.pageSize = pageSize
    this.updateURLParams({ ...this.searchParams, pageSize: pageSize })
    localStorage.setItem(this.pageSizeKey, pageSize.toString())
    // Author Profile needs to know if the other searchParams need to be updated
    this.$emit('updatePageSize')
  }

  updateSortBy(sortBy: string, replace = false) {
    this.updateURLParams({ ...this.searchParams, sortBy }, replace)
  }

  updateOrderBy(orderBy: string) {
    this.updateURLParams({ ...this.searchParams, orderBy })
  }

  getMoreResults() {
    this.concatResults = true
    this.updateURLParams({ ...this.searchParams, pageNumber: this.searchParams.pageNumber + 1 })
  }

  onApplyFacetsHandler(facetGroups: FacetGroup[]) {
    const facets = convertSectionsToFacetsString(facetGroups)
    if (this.facetsFromURL !== facets) {
      const linked = facetGroups
        .filter(g => g.linkedANDSearchSelected)
        .map(g => (g.id))
      this.updateURLParams({ ...this.searchParams, facets, pageNumber: 1, linkedANDSearchFieldsActive: linked })
    }
  }

  onApplyGroupHandler(group: FacetGroup) {
    const index = this.facetGroups.findIndex(g => g.id === group.id)
    this.facetGroups.splice(index, 1, group)

    const facets = convertSectionsToFacetsString(this.facetGroups)
    if (this.facetsFromURL !== facets) {
      const linked = this.facetGroups
        .filter(g => g.linkedANDSearchSelected)
        .map(g => (g.id))
      this.updateURLParams({ ...this.searchParams, facets, pageNumber: 1, linkedANDSearchFieldsActive: linked })
    }
  }

  onClearFacetsHandler(groups: FacetGroup[]) {
    const facets = convertSectionsToFacetsString(groups)
    this.updateURLParams({ ...this.searchParams, facets, linkedANDSearchFieldsActive: undefined })
  }

  onClearGroupHandler(group: FacetGroup) {
    const index = this.facetGroups.findIndex(g => g.id === group.id)

    if (index === -1) return

    this.facetGroups.splice(index, 1, group)

    const facets = convertSectionsToFacetsString(this.facetGroups)
    if (this.facetsFromURL !== facets) {
      this.updateURLParams({ ...this.searchParams, facets, linkedANDSearchFieldsActive: undefined })
    }
  }

  onClearFilters() {
    this.updateURLParams({ ...this.searchParams, facets: '' })
  }

  // onSearchFacetsDebounceHandler = debounce(this.onSearchFacetsHandler, 350)
}
