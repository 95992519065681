
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Publication, PublicationType } from './types'
import { parseReview } from '@/utils/utils'
import ArticleSourceModal from '@/components/ArticleSourceModal.vue'
import InternalLinks from '@/components/InternalLinks.vue'
import CollapsibleReview from '@/components/search-results/publications-results/CollapsibleReview.vue'
// import { UPDATE_PREV_NEXT_PUB_ID } from '@/store'

@Component({
  components: {
    ArticleSourceModal,
    InternalLinks,
    CollapsibleReview,
  },
})
export default class BookFormat extends Vue {
  @Prop() item!: Publication
  @Prop({ default: false }) internalAccess!: boolean
  @Prop({ default: false }) unsubbed!: boolean

  showArticleModal = false
  //
  // REACTIVE PROPERTIES
  //
  PublicationType = PublicationType

  //
  // COMPONENT PROPERTIES
  //

  get formattedMRNumber() {
    // Add leading zeros if needed
    const mrNumLength = 7
    const leadingZeros = mrNumLength - this.item.mrnumber.toString().length
    return '0'.repeat(leadingZeros) + this.item.mrnumber
  }

  get articleRoute() {
    return {
      name: 'ArticlePage',
      query: {
        // mr: this.item.mrnumber.toString(),
        mr: this.formattedMRNumber,
      },
    }
  }

  get isPrelim() {
    return this.item.itemType.toLowerCase() === 'prelim'
  }

  get hasArticle() {
    return (this.item.articleUrl && this.item.articleUrl !== '') || (this.item.openURL && this.item.openURL.targetLink.length > 0)
  }

  get title() {
    return parseReview(this.item.titles.title)
  }

  get translatedTitle() {
    return parseReview(this.item.titles.translatedTitle)
  }

  get displayPaging() {
    // Test with MR0620028
    // return this.clearPunctuation(this.paging.text)
    if (this.item.paging && this.item.paging.paging) {
      let text = this.dashReplacement(this.item.paging.paging.text)
      const endChar = text.charAt(text.length - 1)
      if (endChar !== ',' && endChar !== '.') {
        text += '.'
      }
      return text
    } else {
      return ''
    }
  }

  get reviewers() {
    if (this.item.prePubl &&
      this.item.prePubl.reviewer &&
      this.item.prePubl.reviewer.reviewers) {
      return this.item.prePubl.reviewer.reviewers
    } else {
      if (this.item.reviewer &&
      this.item.reviewer.reviewers
      ) {
        return this.item.reviewer.reviewers
      } else {
        return []
      }
    }
  }

  dashReplacement(text: string) {
    text = text.replaceAll('---', '&mdash;')
    text = text.replaceAll('--', '&ndash;')
    return text
  }

  clearPunctuation(text: string) {
    // Clear trailing commas and periods, like in MR0507436
    text = this.dashReplacement(text)
    return text.replace(/\.$|,$/, '')
  }

  formatIssue(issue: string|number, multiIss: string|undefined = undefined) {
    // Some issue numbers include 'no. X,' which messes with the formatting
    let formatted = `${issue}`.replaceAll('no.', '').trim()
    formatted = this.clearPunctuation(formatted)
    if (multiIss) {
      formatted += '-'
      formatted += multiIss.replaceAll('no.', '').trim()
    }
    return formatted
  }

  goToArticle(articleId: number) {
    // this.$store.dispatch(UPDATE_PREV_NEXT_PUB_ID, { publicationId: articleId })
    this.$router.push({
      name: 'ArticlePage',
      query: {
        mr: articleId.toString(),
      },
    })
  }

  get mscRoute() {
    const route = {
      name: 'PublicationsSearch',
      query: {
        query: 'pc:"' + this.item.primaryClass.code + '"',
      },
    }
    return route
  }
}
