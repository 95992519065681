import { SearchParams } from '@/global-types'
import VueRouter from 'vue-router'

export function updateRouter(router: VueRouter, replace = false, freetool = false) {
  return (searchParams: SearchParams): void => {
    const info = {
      name: freetool ? 'Publications' : 'PublicationsSearch',
      query: {
        query: searchParams.query,
        page: searchParams.pageNumber.toString(),
        size: searchParams.pageSize.toString(),
        sort: searchParams.sortBy,
        order: searchParams.orderBy,
        facets: searchParams.facets,
        ls: searchParams.linkedANDSearchFieldsActive?.join(','),
      },
    }

    if (replace) {
      router.replace(info)
    } else {
      router.push(info)
    }
  }
}

export const defaultSearchParams: SearchParams = {
  query: '',
  pageNumber: 1,
  pageSize: 20,
  sortBy: 'newest',
  facets: '',
}
