
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PublicationReviewers } from './types'
import { parseReview, prevReviewSplitter } from '@/utils/utils'

@Component({})
export default class CollapsibleReview extends Vue {
  @Prop() review!: string
  @Prop() reviewer!: PublicationReviewers

  get parsedReview() {
    if (this.review) {
      const currentAndPrev = prevReviewSplitter(this.review)
      if (currentAndPrev.length > 1) { // Only show the current review in the search results
        return parseReview(currentAndPrev[0])
      }
      return parseReview(this.review)
    }

    return null
  }

  toggleShowingFull() {
    const reviewEl = this.$refs.reviewDiv as HTMLElement
    if (reviewEl.classList.contains('fade-bottom')) {
      // It's collapsed
      reviewEl.classList.remove('fade-bottom')
    } else {
      // It's expanded
      reviewEl.classList.add('fade-bottom')
      // Scroll to the parent
      this.scrollToParent()
    }
  }

  scrollToParent() {
    const el = this.$refs.reviewDiv as Element
    const article = el.closest('.article')
    if (article !== null) {
      article.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
}
