
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import GenericFormat from './GenericFormat.vue'
import BookFormat from './BookFormat.vue'
import ResultsCitationsComponent from './ResultsCitationsComponent.vue'
import { Publication, PublicationType } from './types'
import { Results, PublicationFormat } from '@/global-types'
import { PublicationsAPI } from '@/api'
import { forEach } from 'lodash'
import { updateMathJax } from '@/utils/utils'
import { SearchPagination } from '@/components/search-results'

type KeyText = {
  [key: string]: string
}

@Component({
  components: {
    GenericFormat,
    BookFormat,
    ResultsCitationsComponent,
    SearchPagination,
  },
})
export default class ResultsComponent extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ required: true }) publications!: Results<Publication>
  @Prop({ default: false, type: Boolean }) nestedComponentFormat!: boolean
  @Prop({ default: false, type: Boolean }) filtersOpened!: boolean
  @Prop({ default: false }) internalAccess!: boolean
  @Prop({ default: false }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet
  @Prop({ default: false }) query!: string

  @PropSync('currentPage', { type: Number }) syncedCurrentPage!: number
  @PropSync('pageSize', { type: Number }) syncedPageSize!: number
  @PropSync('sortKey', { type: String }) syncedSortKey!: string
  @PropSync('orderKey', { type: String }) syncedOrderKey!: string

  //
  // REACTIVE PROPERTIES
  //
  subscriptionModalVisible = false
  batchOpened = false
  checkAll = false
  selectedMRNumbers : number[] = []
  PublicationType = PublicationType
  sortedKeyText: KeyText = {
    authorsCount: '# Authors',
    oldest: 'Oldest',
    newest: 'Newest',
    citations: 'Citations',
    relevance: 'Relevance',
    paging: 'Paging',
  }

  orderKeyText: KeyText = {
    asc: 'ASC',
    desc: 'DESC',
  }

  showCitationModal = false
  dismissCountDown = 0
  fetchingCitation = false
  citationType = PublicationFormat.AMS
  citationOptions = [
    // { name: 'Plain Text', value: PublicationFormat.PLAIN },
    { name: 'AMSRef', value: PublicationFormat.AMS },
    { name: 'BibTeX', value: PublicationFormat.BIB },
    { name: 'EndNote', value: PublicationFormat.END },
    { name: 'TeX', value: PublicationFormat.TEX },
  ]

  //
  // WATCHERS
  //
  @Watch('publications')
  onPublicationsChanged() {
    // make sure the selectedMRNumbers don't exceed the number of results
    if (this.publications.results.length < this.selectedMRNumbers.length) {
      this.selectedMRNumbers = this.selectedMRNumbers.filter(x => this.publications.results.map(y => y.mrnumber).includes(x))
    }
  }

  // @Watch('syncedPageSize', { immediate: true })
  // updatePageSize(newSize: number) {
  //   this.syncedPageSize = newSize
  //   if (newSize !== this.parentPageSize) {
  //     this.parentPageSize = newSize
  //   }
  // }

  // @Watch('syncedCurrentPage', { immediate: true })
  // updateCurrentPage(newPage: number) {
  //   this.syncedCurrentPage = newPage
  //   if (newPage !== this.parentCurrentPage) {
  //     this.parentCurrentPage = newPage
  //   }
  // }

  // @Watch('parentPageSize', { immediate: true })
  // updateSyncedPageSize() {
  //   this.syncedPageSize = this.parentPageSize
  // }

  // @Watch('parentCurrentPage', { immediate: true })
  // updateSyncedCurrentPage() {
  //   this.syncedCurrentPage = this.parentCurrentPage
  // }

  //
  // COMPUTED PROPERTIES
  //

  get totalPubsClassIfNested() {
    return this.nestedComponentFormat ? 'd-block d-md-none' : ''
  }

  get totalPaginationRows() {
    return this.publications.total > 1000 ? 1000 : this.publications.total
  }

  get isOrderVisible() {
    return ['citations', 'authorsCount'].includes(this.syncedSortKey)
  }

  get preservedOrderSelectedMRNumbers() {
    return this.publications.results.map(x => x.mrnumber).filter(x => this.selectedMRNumbers.includes(x))
  }

  get fullSearchLink() {
    // Not just the query, but the sorting should be used as well
    const link = {
      query: this.query,
      sort: this.syncedSortKey,
      order: this.syncedOrderKey,
    }
    return link
  }

  //
  // LIFECYCLE HOOKS
  //

  updated() {
    updateMathJax()
  }

  mounted() {
    // this.localPageSize = this.syncedPageSize
    // if (this.parentCurrentPage) {
    //   this.syncedCurrentPage = this.parentCurrentPage
    // }
    // if (this.parentPageSize) {
    //   this.syncedPageSize = this.parentPageSize
    // }
    this.setAllCheckboxes(false)
    updateMathJax()
  }

  //
  // METHODS
  //

  updatePageSize(pageSize: number) {
    this.syncedPageSize = pageSize
  }

  updateCurrentPage(pageNumber: number) {
    this.syncedCurrentPage = pageNumber
    this.setAllCheckboxes(false)
    this.checkAll = false
  }

  getMoreResults() {
    this.$emit('more')
    // Append to checkbox
  }

  async getCitations(format: PublicationFormat) {
    // Look at which MR numbers are selected
    const results = await PublicationsAPI.getPublicationFormat(
      [format],
      this.selectedMRNumbers
    )
    return results
  }

  toggleFilters() {
    this.$emit('toggle-filters')
  }

  toggleFiltersModal() {
    this.$emit('toggle-filters-modal')
  }

  sortBy(sortKey: string) {
    this.syncedSortKey = sortKey
  }

  onClickOrderByHandler(orderBy: string) {
    this.syncedOrderKey = orderBy.toLowerCase()
  }

  setAllCheckboxes(status: boolean) {
    if (status) {
      this.selectedMRNumbers = [...this.publications.results.map(x => x.mrnumber)]
    } else {
      this.selectedMRNumbers = []
    }
  }

  mostReviewsCollapsed() {
    const reviews = document.getElementsByClassName('review').length
    const collapsedReviews = document.getElementsByClassName('fade-bottom').length
    return collapsedReviews > (reviews / 2)
  }

  toggleReviews() {
    const reviews = document.getElementsByClassName('review')
    const mostCollapsed = this.mostReviewsCollapsed()
    forEach(reviews, x => {
      if (mostCollapsed) {
        x.classList.remove('fade-bottom')
      } else {
        x.classList.add('fade-bottom')
      }
    })
  }

  showUnsubbedModal() {
    this.subscriptionModalVisible = true
  }

  countDownChanged(dismissCountDown: number): void {
    this.dismissCountDown = dismissCountDown
  }
}
